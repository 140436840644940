<template>
  <v-data-table
    :headers="headers"
    :items="ArrayEmpresas"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Empresas</v-toolbar-title>

         <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

         
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog  v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.nombre_empresa" label="Nombre Empresa">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.usr_avl" label="Usr avl"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field  v-model="editedItem.pwd_avl" label="Pwd avl"></v-text-field>
                  </v-col>
                  <v-col  cols="12" sm="6" md="4">
                   <v-select
                              v-model="editedItem.pais_avl"
                              :items="PaisesArray"
                              label="Pais Avl"
                          ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field  v-model="editedItem.servidor_avl" label="servidor AVL"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>



                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas desactivar Empresa?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>


    
     <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
     </template>


   <template v-slot:[`item.creado`]="{ item }">
         <span>{{ FormatearFechaHora(item.creado)}}</span>         
     </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>

  </v-data-table>
</template>
<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      ArrayEmpresas:[],
      PaisesArray:[{text:'NI', value:'ni'},{text:'SV', value:'sv'},{text:'GT', value:'gt'},{text:'PA', value:'pa'}],

      dialog: false,
      dialogDelete: false,
      headers: [
         { text: 'Nombre empresa', value: 'nombre_empresa' },
        { text: 'Usr avl', value: 'usr_avl' },
        { text: 'Pwd avl', value: 'pwd_avl' },
        { text: 'Pais avl', value: 'pais_avl' },
         { text: 'Servidor avl', value: 'servidor_avl' },
        { text: 'Activo', value: 'activo' },
        { text: 'Creado', value: 'creado' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        nombre_empresa: '',
        usr_avl: '',
        pwd_avl: '',
        pais_avl: '',
        servidor_avl: '',
        activo:1
      },
      defaultItem: {
        nombre_empresa: '',
        usr_avl: '',
        pwd_avl: '',
        pais_avl: '',
        servidor_avl: '',
        activo:1
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva empresa' : 'Editar empresa'
      },

        ...mapState(['usuario', 'token']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
       this.$root.$refs.Home.detenerTimer();
      this.initialize();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArrayEmpresas = [];
        this.listar();
      },
      
       listar () {

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get('empresas', 
               config
          ).then(function(response){
              console.log(response);
              me.ArrayEmpresas=response.data;
           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


      editItem (item) {
        this.editedIndex = this.ArrayEmpresas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayEmpresas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArrayEmpresas.splice(this.editedIndex, 1)
        //damos de baja al registro
           
           
             let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`empresa/${id}`,
                      {'activo':0},
                      
                      config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                      this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          //Object.assign(this.ArrayEmpresas[this.editedIndex], this.editedItem)
            //edicion

                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`empresa/${id}`,
                      { 
                      '_id':this.editedItem._id,
                      'nombre_empresa':this.editedItem.nombre_empresa,
                      'usr_avl':this.editedItem.usr_avl,
                      'pwd_avl':this.editedItem.pwd_avl,
                      'pais_avl':this.editedItem.pais_avl,
                      'servidor_avl':this.editedItem.servidor_avl,
                      'activo':this.editedItem.activo
                       },
                        config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        } else {
        
          //nueva empresa

           let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'actualizando'});

          axios.post('nueva-empresa',
                    { 
                      'nombre_empresa':this.editedItem.nombre_empresa,
                      'usr_avl':this.editedItem.usr_avl,
                      'pwd_avl':this.editedItem.pwd_avl,
                      'pais_avl':this.editedItem.pais_avl,
                      'servidor_avl':this.editedItem.servidor_avl,
                      'activo':this.editedItem.activo
                       },
               config
          ).then(response=>{
          
            this.close();
            console.log(response);
            this.listar();
            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });

        }
        this.close()
      },

       FormatearFechaHora(FechaHoraInicial){
                       var pFechaHoraInicial= new Date(FechaHoraInicial).toISOString().substring(0,10);
                       var textoHora=FechaHoraInicial.split("T")[1];
                        textoHora= textoHora.substring(0,textoHora.length - 5);
                        return(pFechaHoraInicial+' '+textoHora);
     },

    },
  }
</script>